import { apiClient } from '@/main';

export async function getFemaleAdditionalServices() {
  try {
    return await apiClient.get('/female-upgrade-options');
  } catch (error) {
    console.error(error);
  }
}

export async function getMaleAdditionalServices() {
  try {
    return await apiClient.get('/male-upgrade-options');
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetPaymentSubscription() {
  try {
    return await apiClient.get('/subscription');
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetPaymentSubscriptions() {
  try {
    return await apiClient.get('/subscriptions');
  } catch (error) {
    console.error(error);
  }
}
export async function handlePostPaymentSubscriptionSelectPlan(data) {
  try {
    return await apiClient.post('/subscription/select-plan',data);
  } catch (error) {
    console.error(error);
  }
}

export async function handlePostPaymentSubscriptionSelectOptionsPlan(data) {
  try {
    return await apiClient.post('/subscription/select-options-plan',data);
  } catch (error) {
    console.error(error);
  }
}

export async function payAdditionalServices(data) {
  try {
    return await apiClient.post('/tinkoff/make-payment',data);
  } catch (error) {
    console.error(error);
  }
}

export async function payAds(data) {
  try {
    return await apiClient.post('/payment/init',data);
  } catch (error) {
    console.error(error);
  }
}

export async function payForSubscription(data) {
  try {
    return await apiClient.post('/tinkoff/init',data);
  } catch (error) {
    console.error(error);
  }
}


export async function getPaymentOrderIdStatus(id) {
  try {
    return await apiClient.get(`/payments/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function getPremiumPaymentStatus() {
  try {
    return await apiClient.get('/premium-payment');
  } catch (error) {
    console.error(error);
  }
}

export async function historyOfOperation() {
  try {
    return await apiClient.get('/payments');
  } catch (error) {
    console.error(error);
  }
}

export async function deletePaymentOrderId(id) {
  try {
    return await apiClient.delete(`/payments/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function  getPaymentOrderId() {
  try {
    return await apiClient.get('/payments');
  } catch (error) {
    console.error(error);
  }
}

export async function  getPaymentUserUpgradeOptions() {
  try {
    return await apiClient.get('/user-upgrade-options');
  } catch (error) {
    console.error(error);
  }
}

export async function  getPaymentToggleOption(data) {
  try {
    return await apiClient.post('/toggle-option',data);
  } catch (error) {
    console.error(error);
  }
}