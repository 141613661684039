export function updateDragStatus(event) {
  event.preventDefault();
  const rect = event.currentTarget.getBoundingClientRect();

  return !(
    event.clientX < rect.left ||
    event.clientX > rect.right ||
    event.clientY < rect.top ||
    event.clientY > rect.bottom
  );
}