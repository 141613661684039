<template>
  <div class="textarea-wrapper flex flex-col gap-[12px]">
    <div
      v-if="label"
      class="textarea-wrapper-label text-[12px] md:text-[14px]"    >
      {{ label }}
    </div>
    <div :class="[ 'textarea-wrapper-box', textareaClass]">
      <textarea
        id="custom-textarea"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :value="value"
        class="textarea-wrapper-box-textarea scroll-dark-small-variant border-0	outline-0	outline-transparent	border-transparent"
        @input="textareaChange"
        :disabled="isDisabled"
      />
      <div
        v-if="showEmojis && showCounter"
        class="textarea-wrapper-box-bottom-bar"
      >
        <v-menu
          :disabled="isDisabled"
          v-model="isDropdownOpen"
          :close-on-content-click="false"
          location="right"
        >
          <template v-slot:activator="{ props }">
            <smile-icon
              v-if="showEmojis"
              class="textarea-wrapper-box-bottom-bar-icon"
              :class="{
                'textarea-wrapper-box-bottom-bar-icon-active': isDropdownOpen,
                'cursor-pointer': !isDisabled
              }"
              v-bind="props"
            />
          </template>

          <emojis-list-dropdown @selectEmoji="handleSelectEmoji" />
        </v-menu>
        <div
          v-if="showCounter"
          class="textarea-wrapper-box-bottom-bar-char-counter"
        >
          <span class="textarea-wrapper-box-bottom-bar-char-counter-char-count">
            {{ value.length }}
          </span>
          /{{ maxLength }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import EmojisListDropdown from '@/common/EmojisListDropdown.vue';
import SmileIcon from '@/assets/icons/smileIcon.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: 600,
  },
  value: {
    type: String,
    default: '',
  },
  showEmojis: {
    type: Boolean,
    default: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  showCounter: {
    type: Boolean,
    default: true,
  },
  emojisDropdownPosition: {
    type: String,
    default: 'right',
  },
  textareaClass: {
    type: String,
    default: '',
  },
  handleTextareaChange: {
    type: Function,
    default: () => {
    },
  },
});

const isDropdownOpen = ref(false);

const handleSelectEmoji = (emoji) => {
  const updatedValue = props.value + emoji;
  props.handleTextareaChange(updatedValue);
};

const textareaChange = (event) => {
  props.handleTextareaChange(event.target.value);
};
</script>

<style scoped lang="scss">
.textarea-wrapper {
  display: flex;
  flex-direction: column;

  &-label {
    color: var(--primary-invert-alfa-50);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &-box {
    display: flex;
    height: 220px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    background: var(--Background-Bg-Black, #000);

    &-textarea {
      width: 100%;
      height: 100px; /* Adjust the height as needed */
      background-color: transparent;
      resize: none;
      color: #ddd;
      font-size: 16px;
      line-height: 1.5;
      flex: 1;
    }

    &-bottom-bar {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: end;

      &-char-counter {
        color: var(--primary-invert-alfa-40);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        letter-spacing: -0.2px;

        &-char-count {
          color: var(--primary-invert);
        }
      }
    }
  }
}

.textarea-wrapper-box-bottom-bar-icon {
  color: var(--primary-invert-alfa-30);
}

.textarea-wrapper-box-bottom-bar-icon-active {
  color: var(--primary-invert);
}
</style>
