import moment from 'moment';

export function isOlderThan18(selectedDate) {
  const currentDate = moment();
  const birthDate = moment(selectedDate, 'DD.MM.YYYY');
  const age = currentDate.diff(birthDate, 'years');

  return age >= 18;
}

export function getGoingTimeFromDay(remainingHour) {
  const goingHours = 24 - Number(remainingHour);

  return (goingHours / 24) * 100;
}

export function getSecondsPercent(currentTime, allTime) {
  const goingHours = allTime - Number(currentTime);

  return (goingHours / allTime) * 100;
}