<template>
  <div class="app-wrapper">
    <router-view />

    <!-- DIALOGS -->
    <post-ad-dialog v-if="dialogs.adPlace" />
    <payment-ad-dialog  v-if="payAdsDialog.length" />
    <post-ad-note-dialog v-if="dialogs.postAdNote" />
    <chat-have-new-dialog v-if="dialogs.youHaveNewDialog" />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import echo from '@/echo';
import { toNumber } from 'lodash';
import PostAdDialog from '@/components/dialogs/PostAdDialog.vue';
import PaymentAdDialog from '@/components/dialogs/PaymentAdDialog.vue';
import PostAdNoteDialog from '@/components/dialogs/PostAdNoteDialog.vue';
import ChatHaveNewDialog from '@/components/dialogs/ChatHaveNewDialog.vue';
import { onlineChecker, stopOnlineChecker } from '@/helpers/onlineChecker';
import { getHideProfile } from '@/api/Auth';
import { handleGetUserActiveAds, handleGetUserInfo, handleGetUserUnreadLikesCount } from '@/api/Profile';
import { handleGetUnreadMessagesCount, handleUpdateMessageStatus } from '@/api/Chat';
import '@/assets/css/main.scss';
import './themes/theme.css';

const store = useStore();
const route = useRoute();
const router = useRouter();

const typingTimeouts = new Map();

const dialogs = computed(() => store.state.main.dialogs);
const payAdsDialog = computed(() => store.getters['payment/payAds']);
const userInfo = computed(() => store.getters['profile/userInfo']);
const chatList = computed(() => store.getters['chat/chatList']);
const selectedUserChatMessages = computed(() => store.getters['chat/selectedUserChatMessages']);
const profileInfo = computed(() => store.getters['profile/selectedUserPage']);
const userFeedList = computed(() => store.getters['profile/userFeedList']);
const userLikesList = computed(() => store.getters['profile/userLikesList']);

echo.connector.pusher.connection.bind('connected', () => {
  console.log('WebSocket connected successfully');
});

onMounted(async () => {
  document.documentElement.className = 'dark';
  const isHide = await isHideProfile();

  if (isHide) {
    localStorage.setItem('hideProfile', 'true');
    return;
  }

  await getUserInfo();


  if (window.performance) {
    const navigationEntries = performance.getEntriesByType('navigation');
    const isPageReloaded =
        performance.navigation?.type === 1 ||
        (navigationEntries.length && navigationEntries[0].type === 'reload');

    if (isPageReloaded) {
      localStorage.removeItem('adPlace');
      localStorage.removeItem('adData');
    }
  }

  if (localStorage.getItem('adPlace')) {
    store.dispatch('main/openDialog', 'adPlace');
  }else if(localStorage.getItem('accessToken')) {
    await getUserAds();
  }
  if (localStorage.getItem('accessToken')) {
    await onlineChecker();
    await getUnreadMessagesUserCount();
    await getUserUnreadLikesCount();
  }
});

onBeforeUnmount(() => {
  stopOnlineChecker();

  if (userInfo.value && userInfo.value.id) {
    echo.leave(`user.${userInfo.value.id}`);
  }
});

async function getUserInfo() {
  const response = await handleGetUserInfo();

  if (response && response.data) {
    store.dispatch('profile/setUserInfo', response.data);
    startSockets();
  }
}
async function isHideProfile() {
  const response = await getHideProfile();
  if (response && response.data) {
    return response.data.is_site_hidden === 1;
  }
}

async function getUserAds() {
  const response = await handleGetUserActiveAds();

  if (response && response.status === 200) {
    store.dispatch('profile/setUserAd', {
      ...response?.data?.ad,
      countLimit: response?.data?.remaining_ads_count || 0,
      isPublished: !!response?.data?.ad?.content,
    });
  }
}

async function getUnreadMessagesUserCount() {
  const response = await handleGetUnreadMessagesCount();

  if (response && response.data) {
    store.dispatch('chat/setUnreadMessagesCount', response.data.unread_messages_count || 0);
  }
}

async function getUserUnreadLikesCount() {
  const response = await handleGetUserUnreadLikesCount();

  if (response && response.data) {
    store.dispatch('profile/setUserUnreadLikesCount', response.data.new_likes_count || 0);
  }
}

function userIsTyping(data) {
  const updatedList = chatList.value.map((el) => {
    if (el?.interlocutor_id === data?.user_id) {
      return { ...el, typingNow: data?.typing };
    }
    return el;
  });

  store.dispatch('chat/setChatList', updatedList);

  if (typingTimeouts.has(data.user_id)) {
    clearTimeout(typingTimeouts.get(data.user_id));
  }

  const timeout = setTimeout(() => {
    const clearedList = chatList.value.map((el) => {
      if (el?.interlocutor_id === data?.user_id) {
        return { ...el, typingNow: false };
      }
      return el;
    });

    store.dispatch('chat/setChatList', clearedList);
    typingTimeouts.delete(data.user_id);
  }, 2500);

  typingTimeouts.set(data.user_id, timeout);
}

async function addUserWritingMessage(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;
  let updateStatusData;

  if (route.name === 'ChatUser' && userId === data.messages[0].from_user_id) {
    data.messages.map((el) => {
      const updatedUserChatMessages = [
        ...selectedUserChatMessages.value,
        el
      ];

      updateStatusData = {
        user_id: el.from_user_id,
        status: 'read'
      };

      store.dispatch('chat/setSelectedUserChatMessages', updatedUserChatMessages);
    });
    await handleUpdateMessageStatus(updateStatusData);
  }
}

function addMySendingMessage(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;
  if (route.name === 'ChatUser' && userId === parseInt(data.messages[0].to_user_id)) {

    data.messages.map((el) => {
      const updatedUserChatMessages = [
        ...selectedUserChatMessages.value,
        el
      ];
      store.dispatch('chat/setSelectedUserChatMessages', updatedUserChatMessages);
    });
  }
}

function userIsBlocked(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;
  const isChatUserBlocked = route.name === 'ChatUser' && (userId === data.blocked_user_id || userId === data.user_id);

  if (route.name === 'Chat' || isChatUserBlocked) {
    store.dispatch('chat/resetSelectedUserChat');
    const filteredList = chatList.value.filter(
      el => el.interlocutor_id !== data.blocked_user_id && el.interlocutor_id !== data.user_id
    );
    store.dispatch('chat/setChatList', filteredList);
    router.push({ name: 'Chat' });
  }
}

function handleDeleteChatForBoth(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;
  const isChatUser = route.name === 'ChatUser' && (userId === Number(data.interlocutor_id) || userId === data.user_id);

  const filteredList = chatList.value.filter(
    el => el.interlocutor_id !== data.user_id && el.interlocutor_id !== Number(data.interlocutor_id)
  );

  store.dispatch('chat/setChatList', filteredList);

  if (route.name === 'Chat' || isChatUser) {
    router.push({ name: 'Chat' });
    store.dispatch('chat/resetSelectedUserChat');
  }
}

function setAllMessagesLikeSeen(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;
  const isChatUser = route.name === 'ChatUser' && userId === data.profileId;

  if (isChatUser) {
    const updatedMessages = selectedUserChatMessages.value.map(el => {
      return { ...el, status: 'read' };
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessages);
  }
}

function changeEditedMessage(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.map(el => {
      if (el.id === data.id) {
        return {
          ...el,
          content: data.content,
          edited: true
        };
      }

      return el;
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function removeDeletedMessage(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.map(el => {
      if (el.id === toNumber(data.id)) {
        return {
          ...el,
          message_removed: true
        };
      }
      return el;
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function removePhotoFromList(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.map(el => {
      if (el.id === data.message_id) {
        const updatedMessage = { ...el, message_removed: 1 };

        delete updatedMessage.photo.availablePercent;

        return updatedMessage;
      }
      return el;
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function changeUserUnreadMessagesCount(data) {
  const dataMap = data.reduce((acc, item) => {
    acc[item.from_user_id] = item;
    return acc;
  }, {});


  const updatedList = chatList.value.map((el) => {
    const match = dataMap[el?.interlocutor_id];

    if (match) {
      return {
        ...el,
        unread_count: match.unread_count,
        last_message: match.last_message?.content,
        last_message_time: match.last_message?.created_at,
        last_message_type: match.last_message_type,
      };
    }

    return { ...el };
  });

  store.dispatch('chat/setChatList', updatedList);
}

function handleLikeOrUnlikeMessage(data) {
  const likedOrUnlikedMessages = selectedUserChatMessages.value.map((el) => {
    if (el?.id === data?.message_id) {
      
      return { ...el, likes_types: data.type };
    }
    return el;
  });

  store.dispatch('chat/setSelectedUserChatMessages', likedOrUnlikedMessages);
}

function addOrChangeUserAds(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;

  if (userId && userId === data.ads.user_id) {
    const updatedProfileInfo = {
      ...profileInfo.value,
      ad: data.ads
    };

    store.dispatch('profile/setSelectedUserPage', updatedProfileInfo);
  } else if (route.name === 'UserFeed' && userFeedList.value.length) {
    const updatedFeedList = userFeedList.value.map(el => {
      if (el.id === data.ads.user_id) {
        return { ...el, ads: data.ads };
      }

      return el;
    });

    store.dispatch('profile/setUserFeedList', updatedFeedList);
  } else if (route.name === 'UserLikes' && userLikesList.value.length) {
    const updatedLikesList = userLikesList.value.map(el => {
      if (el.id === data.ads.user_id) {
        return { ...el, ads: data.ads };
      }

      return el;
    });

    store.dispatch('profile/setUserLikesList', updatedLikesList);
  }
}

function deleteUserAds(data) {
  const userId = route.params.id ? parseInt(atob(route.params.id)) : null;

  if (userId && userId === data.user_id) {
    const updatedProfileInfo = {
      ...profileInfo.value,
      ad: data.ads
    };

    store.dispatch('profile/setSelectedUserPage', updatedProfileInfo);
  } else if (route.name === 'UserFeed' && userFeedList.value.length) {
    const updatedFeedList = userFeedList.value.map(el => {
      if (el.id === data.user_id) {
        return { ...el, ads: data.ads };
      }

      return el;
    });

    store.dispatch('profile/setUserFeedList', updatedFeedList);
  } else if (route.name === 'UserLikes' && userLikesList.value.length) {
    const updatedLikesList = userLikesList.value.map(el => {
      if (el.id === data.user_id) {
        return { ...el, ads: data.ads };
      }

      return el;
    });

    store.dispatch('profile/setUserLikesList', updatedLikesList);
  }
}

function changeUserUnreadLikesCount (data) {
  store.dispatch('profile/setUserUnreadLikesCount', data.newLikesCount || 0);
}

function handleChangeHideProfile(data) {
  if (data?.is_site_hidden) {
    router.push({name: 'hideProfile'});
  }else {
    router.push({name: 'accountActions'});
  }

  localStorage.setItem('hideProfile', data?.is_site_hidden);
}

function handleStartTimer(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const messagesList = selectedUserChatMessages.value.map((el) => {
      if (el?.id === data?.message_id && data.photo_id) {
        return {
          ...el,
          photo: el.photo?.id === data.photo_id
            ? {
              ...el.photo,
              availablePercent: data.status,
              timer: data.formattedTime,
            }
            : el.photo,
        };
      }
      return el;
    });

    store.dispatch('chat/setSelectedUserChatMessages', messagesList);
  }
}

function handleChangeDialogLastMessage (data) {
  if (chatList.value.length && route.name === 'ChatUser') {
    const updatedList = chatList.value.map((el) => {
      if (el.interlocutor_id === data.from_user_id || el.interlocutor_id === data.to_user_id) {
        return {
          ...el,
          last_message: data.last_message_content,
          last_message_time: data.updated_at,
          last_message_type: data.last_message_type,
        };
      }

      return { ...el };
    });
    store.dispatch('chat/setChatList', updatedList);
  }
}

function startSockets() {
  if (userInfo.value && userInfo.value.id) {
    echo.private(`user.${userInfo.value.id}`)
      .listen('.LikeNotification', (event) => {
        console.log('LikeNotification received:', event);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UnreadMessagesFromUsersUpdated', (event) => {
        changeUserUnreadMessagesCount(event.unread_messages_from_users);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UnreadMessagesCountUpdated', (event) => {
        store.dispatch('chat/setUnreadMessagesCount', event.unread_messages_count || 0);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.MessageLikedToSender', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.siteVisibilityToggled', (data) => {
        handleChangeHideProfile(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.MessageLiked', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UserBlocked', (data) => {
        userIsBlocked(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.ChatDeletedForBoth', (data) => {
        handleDeleteChatForBoth(data);
      });
    echo.private(`messages.${userInfo.value.id}`)
      .listen('.MessageRemovedPhoto', (data) => {
        removePhotoFromList(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.MessageReadByBothUsers', (data) => {
        setAllMessagesLikeSeen(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageUnlikedToSender', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageUnliked', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.channel(`typing.${userInfo.value.id}`)
      .listen('.TypingIndicator', (data) => {
        userIsTyping(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .listen('.MessageSent', (data) => {
        addUserWritingMessage(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .stopListening('.MessageSentToSender')
      .listen('.MessageSentToSender', (data) => {
        addMySendingMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MutualLike', (data) => {
        store.dispatch('main/setDialogData', { dialog: 'chatHaveNewDialog', data: data.profileId });
        store.dispatch('main/openDialog', 'youHaveNewDialog');
      });
    echo.channel(`message.${userInfo.value.id}`)
      .listen('.MessageDeleted', (data) => {
        removeDeletedMessage(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .listen('.MessageEdited', (data) => {
        changeEditedMessage(data);
      });
    echo.channel('ads-created')
      .listen('.AdCreated', (data) => {
        addOrChangeUserAds(data);
      });
    echo.channel('ads-channel')
      .listen('.AdsUpdated', (data) => {
        addOrChangeUserAds(data);
      });
    echo.channel('ads-channel')
      .listen('.AdDeleted', (data) => {
        deleteUserAds(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.NewLikesCount', (data) => {
        changeUserUnreadLikesCount(data);
      });
    echo.channel(`photo-channel.${userInfo.value.id}`)
      .listen('.TemporaryPhotoView', (data) => {
        handleStartTimer(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageEditedWithPrevious', (data) => {
        handleChangeDialogLastMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageDeletedWithPrevious', (data) => {
        handleChangeDialogLastMessage(data);
      });
  } else {
    console.warn('User info is not available. Cannot start sockets.');
  }
}
</script>

<style>
.app-wrapper {
  font-family: "Exo 2", serif;
  display: flex;
  justify-content: center;
  flex: 1;
}
</style>
