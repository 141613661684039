import { apiClient } from '@/main';

export async function handleSaveImage(payload) {
  try {
    return await apiClient.post(
      '/photos/save',
      {
        photos: payload,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetImages(query) {
  try {
    let requestUrl = '/user/photos';

    if (query) {
      requestUrl += `?is_private=${query.is_private || false}&from_settings=${query.from_settings || false}`;
    }

    if (!query) {
      requestUrl += '?is_private=false&from_settings=false';
    }

    return await apiClient.get(requestUrl);
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteImage(id) {
  try {
    return await apiClient.delete(`/deletePhotos/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function handleSetAvatar(id) {
  try {
    return await apiClient.post(`/photos/${id}/set-avatar`);
  } catch (error) {
    console.error(error);
  }
}

export async function handleModerateImage(image) {
  try {
    return await apiClient.post('/photos/moderate',
      { image },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getImageLikedEmojis(photoId) {
  try {
    return await apiClient.get(`/photos/${photoId}/likes`);
  } catch (error) {
    console.error(error);
  }
}

export async function likeImageByEmoji(payload) {
  try {
    return await apiClient.post(`/photos/${payload.photo_id}/likes`, payload);
  } catch (error) {
    console.error(error);
  }
}
