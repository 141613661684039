<template>
  <Dialog
    :close-dialog="closePayAdDialog"
    dialogOverlayClass=" md:items-center md:fixed absolute top-none md:top-[0] h-full md:h-[100vh]"
    dialog-class="flex absolute bottom-[0] md:bottom-none md:relative w-full md:max-w-[790px] w-[97%] md:w-full p-[24px_16px] md:p-[48px] flex-col items-center gap-[24px] md:gap-[40px] rounded-[16px_16px_0px_0px] md:rounded-[32px] bg-[var(--primary-settings-background)] shadow-[0px_-1px_1px_0px_rgba(226,23,120,0.35),0px_1px_2px_0px_rgba(226,23,120,0.35),0px_16px_24px_-8px_rgba(226,23,120,0.24)] backdrop-blur-[12px]"
  >
    <div
      @click="closePayAdDialog"
      class="cursor-pointer absolute top-[8px] right-[8px] md:right-[48px] md:top-[48px] p-[6px] flex"
    >
      <cross-icon class="text-[--primary-invert-alfa-30]"/>
    </div>
    <span class="self-stretch text-[--primary-invert] text-[17px] md:text-[22px] font-[700] leading-[130%] tracking-[0.2px]">
      {{ $t('payment.payAds.title') }}
    </span>

    <div class="flex flex-col items-start gap-[6px] self-stretch">
      <payment-subscription-plan-card
        v-for="plan in paymentAds"
        :key="plan.id"
        :plan="plan"
        @click="toggleCheckbox(plan.id)"
        :is-checked="'byRussianBankCard' === plan.id"
      />
    </div>

    <div class="flex flex-col items-start gap-[16px] md:gap-[24px] self-stretch">
      <span class="text-[--primary-invert] text-[15px] md:text-[18px] font-[400] leading-[1.4] tracking-[0.15px]">
        {{ $t('payment.paymentSubscription.paymentDetails') }}
      </span>
      <payment-additional-options-details :plans="plans" />
    </div>

    <Button
      :text="$t('button.pay')"
      size="none"
      custom-class="w-full p-[16px_24px] md:p-[24px]"
      button-text-class="font-[700] leading-[0.67] tracking-[0.4px] md:leading-[1.2] md:tracking-[0.8px] text-[12px] md:text-[16px]"
      @handleClick="submitSelectedPlan"
    />
  </Dialog>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Dialog from '@/common/Dialog.vue';
import Button from '@/common/Button.vue';
import PaymentAdditionalOptionsDetails from '@/components/payment/components/PaymentAdditionalOptionsDetails.vue';
import PaymentSubscriptionPlanCard from '@/components/payment/components/PaymentSubscriptionPlanCard.vue';
import CrossIcon from '@/assets/icons/cross.vue';
import { paymentAds } from '@/constants/payment';
import { payAds } from '@/api/payment';

const store = useStore();
const router = useRouter();

const selectedPlan = ref(null);
const plans = computed(() => store.getters['payment/payAds']);

function toggleCheckbox(id) {
  if (!selectedPlan.value || selectedPlan.value !== id) {
    selectedPlan.value = id;
  } else {
    selectedPlan.value = '';
  }
}

async function submitSelectedPlan() {
  const response = await payAds({
    id: plans.value[0]?.id || 'payAds',
    amount: plans.value[0]?.discountedPrice || 99,
    route: router.currentRoute.value.path
  });

  if (response?.data?.PaymentURL) {
    window.location.href = response.data.PaymentURL;
  }
}

function closePayAdDialog() {
  delete localStorage.adPlace;
  store.dispatch('payment/setPayAds', []);
}
</script>
