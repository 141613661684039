<template>
  <svg
    class="base-timer__svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g class="fill-none stroke-none">
      <rect
        class="base-timer__path-elapsed"
        :style="{ stroke: `var(${loadingColor})` }"
        :x="strokeWidth / 2"
        :y="strokeWidth / 2"
        :width="width - strokeWidth"
        :height="height - strokeWidth"
        :rx="borderRadius"
        :ry="borderRadius"
      />
      <rect
        id="base-timer-path-remaining"
        class="base-timer__path-remaining"
        :class="defaultColor"
        :x="strokeWidth / 2"
        :y="strokeWidth / 2"
        :width="width - strokeWidth"
        :height="height - strokeWidth"
        :rx="borderRadius"
        :ry="borderRadius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="rectDasharray"
        stroke-dashoffset="0"
      />
    </g>
  </svg>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  percent: {
    type: Number,
    required: true,
    validator: (value) => value >= 0 && value <= 100,
  },
  width: {
    type: Number,
    default: 76,
  },
  height: {
    type: Number,
    default: 44,
  },
  borderRadius: {
    type: Number,
    default: 20,
  },
  strokeWidth: {
    type: Number,
    default: 2,
  },
  defaultColor: {
    type: String,
    default: 'text-[--primary-invert]',
  },
  loadingColor: {
    type: String,
    default: '',
  }
});

const fullDashArray = computed(() => (props.width + props.height) * 1.7);
const rectDasharray = computed(() => `${(props.percent / 100) * fullDashArray.value} ${fullDashArray.value}`);
</script>

<style scoped>
.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__path-elapsed {
  stroke-width: v-bind(strokeWidth);
  stroke: v-bind(loadingColor);
}

.base-timer__path-remaining {
  stroke-width: v-bind(strokeWidth);
  stroke-linecap: round;
  transition: 0.3s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
  fill: none;
}
</style>
