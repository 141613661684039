
const state = () => ({
  paymentPlans: [],
  selectedPlan: null,
  activePlan: null,
  autoSubscription: false,
  payAds:[],
  additionalServices:[],
  paymentStatus: null,
  activeAdditionalServices: false
});

const getters = {
  paymentPlans: (state) => state.paymentPlans,
  selectedPlan: (state) => state.selectedPlan,
  additionalServices: (state) => state.additionalServices,
  payAds: (state) => state.payAds,
  activePlan: (state) =>state.activePlan,
  autoSubscription: (state) => state.autoSubscription,
  activeAdditionalServices: (state) => state.activeAdditionalServices,
  paymentStatus: (state) => state.paymentStatus,
};

const actions = {
  selectPlan({ commit }, plan) {
    commit('SET_SELECTED_PLAN', plan);
  },

  setAdditionalServices({ commit }, plan) {
    commit('SET_ADDITION_SERVICES', plan);
  },

  setPayAds({ commit }, plan) {
    commit('SET_PAY_ADS', plan);
  },

  activePlan({ commit }, plan) {
    commit('SET_ACTIVE_PLAN', plan);
  },

  async activeAdditionalServices({ commit }, plan) {
    commit('SET_ACTIVE_ADDITION_SERVICES', plan);
  },

  autoSubscription({ commit }, plan) {
    commit('SET_AUTO_SUBSCRIPTION', plan);
  },

};

const mutations = {

  SET_SELECTED_PLAN(state, plan) {
    state.selectedPlan = plan;
  },

  SET_ACTIVE_PLAN(state, plan) {
    state.activePlan = plan;
  },

  SET_ADDITION_SERVICES(state, plan) {
    state.additionalServices = plan;
  },

  SET_PAY_ADS(state, plan) {
    state.payAds = plan;
  },

  SET_ACTIVE_ADDITION_SERVICES(state, plan) {
    state.activeAdditionalServices = plan;
  },

  SET_AUTO_SUBSCRIPTION(state, plan) {
    state.autoSubscription = plan;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};