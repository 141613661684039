import { i18n } from '@/i18n';

export  const FREE_TARIFF_NAME = 'free';
export  const PREMIUM_TARIFF_NAME = 'premium';

export const benefits = [
  {
    name:  i18n.global.t('payment.takeOffTheShackles.unrestrictedAccess'),
    id: 'unrestrictedAccess'
  },
  {
    name:  i18n.global.t('payment.takeOffTheShackles.filteringExactSearch'),
    id: 'filteringExactSearch'
  },
  {
    name:  i18n.global.t('payment.takeOffTheShackles.unlimitedLikesAndMessages'),
    id: 'unlimitedLikesAndMessages'
  },
  {
    name:  i18n.global.t('payment.takeOffTheShackles.viewAllPhotoAlbums'),
    id: 'viewAllPhotoAlbums'
  },
  {
    name:  i18n.global.t('payment.takeOffTheShackles.messageInformation'),
    id: 'messageInformation'
  }
];

export const additionalNiceThings = [
  {
    title:  i18n.global.t('payment.additionalNiceThings.unconditionalSuperiority.title'),
    description:  i18n.global.t('payment.additionalNiceThings.unconditionalSuperiority.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'male_superiority'
  },
  {
    title:  i18n.global.t('payment.additionalNiceThings.secretPlayer.title'),
    description:  i18n.global.t('payment.additionalNiceThings.secretPlayer.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'secret_player'
  },
  {
    title:  i18n.global.t('payment.additionalNiceThings.topOfList.title'),
    description:  i18n.global.t('payment.additionalNiceThings.topOfList.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'center_attention'
  },
  {
    title:  i18n.global.t('payment.totalAmount'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'total'
  },
  {
    title:  i18n.global.t('payment.allTogether.title'),
    description:  i18n.global.t('payment.allTogether.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'male_all_inclusive'
  }
];

export const niceThings = [
  {
    title:  i18n.global.t('payment.niceThings.inTheSpotlight.title'),
    description:  i18n.global.t('payment.niceThings.inTheSpotlight.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'center_attention'
  },
  {
    title:  i18n.global.t('payment.additionalNiceThings.secretPlayer.title'),
    description:  i18n.global.t('payment.additionalNiceThings.secretPlayer.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'secret_player'
  },
  {
    title:  i18n.global.t('payment.totalAmount'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'total'
  },
  {
    title:  i18n.global.t('payment.allTogether.title'),
    description:  i18n.global.t('payment.allTogether.description'),
    discountedPrice: '0',
    oldPrice: '0',
    id: 'female_all_inclusive'
  }
];

export const paymentSubscription = [
  {
    title:  i18n.global.t('payment.paymentSubscription.byRussianBankCard.title'),
    description:  i18n.global.t('payment.paymentSubscription.byRussianBankCard.description'),
    id: 'byRussianBankCard'
  },
  {
    title:  i18n.global.t('payment.paymentSubscription.byForeignBankCard.title'),
    description:  i18n.global.t('payment.paymentSubscription.byForeignBankCard.description'),
    id: 'byForeignBankCard'
  },
  {
    title:  i18n.global.t('payment.paymentSubscription.fastPaymentSystem.title'),
    description:  i18n.global.t('payment.paymentSubscription.fastPaymentSystem.description'),
    id: 'fastPaymentSystem'
  }
];

export const paymentAds = [
  {
    title:  i18n.global.t('payment.payAds.crypto.title'),
    description:  i18n.global.t('payment.payAds.crypto.description'),
    id: 'crypto'
  },
  {
    title:  i18n.global.t('payment.paymentSubscription.byRussianBankCard.title'),
    description:  i18n.global.t('payment.paymentSubscription.byRussianBankCard.description'),
    id: 'byRussianBankCard'
  },
  {
    title:  i18n.global.t('payment.paymentSubscription.byForeignBankCard.title'),
    description:  i18n.global.t('payment.paymentSubscription.byForeignBankCard.description'),
    id: 'byForeignBankCard'
  },
  {
    title:  i18n.global.t('payment.paymentSubscription.fastPaymentSystem.title'),
    description:  i18n.global.t('payment.paymentSubscription.fastPaymentSystem.description'),
    id: 'fastPaymentSystem'
  }
];

export const paidTariff = [
  {
    title:  i18n.global.t('payment.additionalNiceThings.unconditionalSuperiority.title'),
    description:  i18n.global.t('payment.additionalNiceThings.unconditionalSuperiority.description'),
    id: 'male_superiority'
  },
  {
    title:  i18n.global.t('payment.additionalNiceThings.secretPlayer.title'),
    description:  i18n.global.t('payment.additionalNiceThings.secretPlayer.description'),
    id: 'secret_player'
  },
  {
    title:  i18n.global.t('payment.additionalNiceThings.topOfList.title'),
    description:  i18n.global.t('payment.additionalNiceThings.topOfList.description'),
    id: 'center_attention'
  },
  {
    title:  i18n.global.t('payment.totalAmount'),
    id: 'total'
  },
];
