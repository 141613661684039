<template>
  <Dialog
    :close-dialog="closeAdNodeDialog"
    dialogOverlayClass=" md:items-center md:fixed absolute top-none md:top-[0] h-full md:h-[100vh]"
    dialog-class="flex absolute bottom-[0] md:bottom-none md:relative w-full md:max-w-[790px] w-[97%] md:w-full p-[24px_16px_82px_16px] md:p-[48px] flex-col items-center gap-[24px] md:gap-[40px] rounded-[16px_16px_0px_0px] md:rounded-[32px] bg-[var(--primary-settings-background)] shadow-[0px_-1px_1px_0px_rgba(226,23,120,0.35),0px_1px_2px_0px_rgba(226,23,120,0.35),0px_16px_24px_-8px_rgba(226,23,120,0.24)] backdrop-blur-[12px]"
  >
    <div
      @click="closeAdNodeDialog"
      class="absolute right-[16px] top-[16px] flex p-[6px] cursor-pointer text-[--primary-invert-alfa-30] hover:text-[--primary-invert]"
    >
      <cross-icon />
    </div>

    <div class="flex flex-col gap-[16px] md:gap-[24px] self-stretch">
      <span class="text-[--primary-invert] text-[20px] md:text-[28px]  font-[700] leading-[120%] tracking-[0.4px]">
        {{ $t('postAnnouncement.postAdNote.title') }}
      </span>
      <span class="text-[--primary-invert-alfa-60] text-[13px] md:text-[16px] font-[400] leading-[140%] md:tracking-[-0.25px]">
        {{ $t('postAnnouncement.postAdNote.description') }}
      </span>
    </div>
    <div class="flex items-center gap-[24px] md:self-stretch w-inherit">
      <div
        class="flex items-center gap-[8px] md:gap-[12px] flex-1 w-inherit overflow-hidden"
      >
        <div
          class="flex p-[6px_8px] md:p-[8px_10px] justify-center items-center gap-[8px] rounded-[16px] "
          :class="{'bg-[--primary-pink]': userAd?.countLimit,'bg-[--primary-invert-alfa-30]': !userAd?.countLimit }"
        >
         <span class="text-[--primary-invert-alfa-60] text-[12px] md:text-[16px] font-[700] leading-[90%] tracking-[0.4px] md:tracking-[0.8px] uppercase flex-1">
           {{ userAd?.countLimit }}
         </span>
        </div>
        <span class="text-[--primary-invert-alfa-60] text-[11px] md:text-[12px] font-normal leading-[150%] tracking-[0.6px]">
          {{ $tc('postAnnouncement.freeAds', userAd?.countLimit === 5 ? 0 : userAd?.countLimit)  }}
        </span>
      </div>
      <Button
        size="none"
        custom-class="p-[14px_20px] md:p-[16px_18px]"
        :text="$t('button.okay')"
        @handle-click="closeAdNodeDialog"
      />
    </div>

  </Dialog>
</template>

<script setup>
import { computed } from 'vue';
import Dialog from '@/common/Dialog.vue';
import { useStore } from 'vuex';
import CrossIcon from '@/assets/icons/cross.vue';
import Button from '@/common/Button.vue';

const store = useStore();

const userAd = computed(() => store.getters['profile/userAd']);
function closeAdNodeDialog() {
  store.dispatch('main/closeDialog', 'postAdNote');
}
</script>
