import { handleCheckOnlineStatus } from '@/api/Auth';

let intervalId;

export async function onlineChecker() {
  await handleCheckOnlineStatus({ is_online: true });

  intervalId = setInterval(async () => {
    await handleCheckOnlineStatus({is_online: true});
  }, 300000);
}

export async function stopOnlineChecker() {
  clearInterval(intervalId);
  await handleCheckOnlineStatus({ is_online: false });
}
