import { createStore } from 'vuex';
import main from './modules/main';
import auth from './modules/auth';
import profile from './modules/profile';
import chat from './modules/chat';
import payment from './modules/payment';

const store = createStore({
  modules: {
    main,
    auth,
    profile,
    chat,
    payment
  },
});

export default store;
