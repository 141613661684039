<template>
  <Dialog
    :close-dialog="closeAdDialog"
    dialogOverlayClass=" md:items-center md:fixed absolute top-none md:top-[0] h-full md:h-[100vh]"
    dialog-class="flex absolute bottom-[0] md:bottom-none md:relative w-full md:max-w-[790px] w-[97%] md:w-full p-[24px_16px_82px_16px] md:p-[48px] flex-col items-center gap-[24px] md:gap-[40px] rounded-[16px_16px_0px_0px] md:rounded-[32px] bg-[var(--primary-settings-background)] shadow-[0px_-1px_1px_0px_rgba(226,23,120,0.35),0px_1px_2px_0px_rgba(226,23,120,0.35),0px_16px_24px_-8px_rgba(226,23,120,0.24)] backdrop-blur-[12px]"
  >
    <div
      @click="closeAdDialog"
      class="absolute right-[16px] top-[16px] flex p-[6px] cursor-pointer text-[--primary-invert-alfa-30] hover:text-[--primary-invert]"
    >
      <cross-icon />
    </div>
    <div class="flex flex-col gap-[16px] md:gap-[24px] self-stretch">
      <span class="text-[--primary-invert] text-[20px] md:text-[28px]  font-[700] leading-[120%] tracking-[0.4px]">
        {{ userAd?.isPublished ? $t('common.ad') : $t('postAnnouncement.title') }}
      </span>
      <span class="text-[--primary-invert-alfa-60] text-[13px] md:text-[16px] font-[400] leading-[140%] md:tracking-[-0.25px]">
        {{ $t('postAnnouncement.description') }}
      </span>
    </div>

    <div class="flex flex-col items-start gap-[8px] md:gap-[16px] self-stretch">
      <div
        @click.stop="triggerFileInput"
        @dragenter="dragStatus"
        @dragleave="dragStatus"
        @dragover.prevent
        @drop="onDrop"
        class="flex p-[16px] md:p-[24px] box-border items-center gap-[16px] md:gap-[48px] self-stretch rounded-[16px] md:rounded-[32px] bg-[--primary-background-dark] "
        :class="{ 'outline-dashed outline-2 outline-[--primary-pink]': isDragging, 'cursor-pointer':  !userAd.isPublished}"
      >
        <div
          v-if="isModerated"
          class="flex flex-col gap-[8px] items-center justify-center bg-[--primary] rounded-[8px] md:rounded-[24px] w-[80px] h-[80px] md:w-[144px] md:h-[144px]"
        >
          <span
            class="text-[--primary-invert] text-[12px] no-italic font-[400] linear-[150%] tracking-[0.6px] text-center"
            :class="{
              'text-[--primary-pink]': isDragging,
            }"
          >
            {{ $t('common.photoRejected') }}
          </span>
          <info-circle-icon
            class="text-[--primary-invert]"
            :class="{
              'add-to-favorites-icon-dragging': isDragging,
            }"
          />
        </div>
        <div
          v-else-if="!userAd?.photo_url"
          class=" flex items-center justify-center bg-[--primary] rounded-[8px] md:rounded-[24px] w-[80px] h-[80px] md:w-[144px] md:h-[144px]"
        >
          <add-to-favorites-icon
            class="fill-[--primary-invert-alfa-40]"
            :class="{
              'add-to-favorites-icon-dragging': isDragging,
              'add-to-favorites-icon': !isDragging,
            }"
          />
        </div>
        <img
          v-else
          :src="userAd?.photo_url"
          alt="Загруженное изображение"
          class="w-[80px] h-[80px] md:w-[144px] md:h-[144px] rounded-[8px] md:rounded-[24px] object-cover bg-cover"
        />
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          accept="image/*"
          @change="handleFileChange"
        />

        <div class="flex flex-col justify-center items-start gap-[24px] flex-1">
          <span
            class="text-[--primary-invert-alfa-40] text-[13px] md:text-[16px] font-[400] leading-[140%] md:leading-[150%] md:tracking-[-0.25px]"
            :class="{'hidden md:flex': userAd?.photo_url}"
          >
            {{ message }}
          </span>
          <div
            v-if="isModerated"
            class="flex justify-center items-center gap-[6px] cursor-pointer"
          >
            <sky-icon
              v-if="isModerated"
              class="text-[--primary-invert-alfa-30] w-[16px] h-[16px]"
            />
            <span class="text-[--primary-invert-alfa-40] text-[16px] md:text-[16px] font-[400] tracking-[0.2px]">
              {{ $t('postAnnouncement.loadOtherPhoto') }}
            </span>
          </div>
          <div
            v-else-if="userAd?.photo_url && !userAd?.isPublished"
            @click.stop="removeImage"
            class="flex justify-center items-center gap-[6px] cursor-pointer"
          >
            <span class="p-[4px] flex items-center">
              <cross-icon class="text-[--primary-invert-alfa-30] w-[8px] h-[8px]"/>
            </span>
            <span class="text-[--primary-invert-alfa-40] text-[16px] md:text-[16px] font-[400] tracking-[0.2px]">
              {{ $t('postAnnouncement.photo.title') }}
            </span>
          </div>
        </div>
      </div>
      <div class=" w-full">
        <Textarea
          :maxLength="360"
          :value="userAd?.content"
          :handle-textarea-change="(value) => handleTextChange(value)"
          :placeholder="$t('postAnnouncement.inputPlaceholder')"
          :is-disabled="userAd?.isPublished"
        />
      </div>
    </div>
    <div
      v-if="userAd?.isPublished && userAd?.countLimit"
      class="flex justify-between items-center w-[100%]"
    >
      <div class="flex p-[8px_10px] justify-center align-center gap-[8px] rounded-[16px] bg-[--primary-pink] h-fit">
        <span class="text-center text-[16px] font-bold leading-[120%] tracking-[0.8px] uppercase text[--primary-invert-alfa-60]">
        {{ $t('common.published') }}
        </span>
      </div>
      <div class="w-fit h-[44px] relative flex justify-center items-center">
        <timer-icon
          :percent="100 - getGoingTimeFromDay(userAd?.remaining_hours)"
          loading-color="--primary-invert-alfa-30"
        />
        <span class="absolute text-[12px] font-[400] no-italic text-[--primary-invert] leading-[140%] tracking-[0.6px]">
          {{ $tc('common.time', userAd?.remaining_hours || 1, { time: userAd?.remaining_hours || 1 } ) }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="flex items-center gap-[24px] md:self-stretch w-inherit"
    >
      <div
        class="flex items-center gap-[8px] md:gap-[12px] flex-1 w-inherit overflow-hidden"
      >
        <div
          class="flex p-[6px_8px] md:p-[8px_10px] justify-center items-center gap-[8px] rounded-[16px] "
          :class="{'bg-[--primary-pink]': userAd?.countLimit,'bg-[--primary-invert-alfa-30]': !userAd?.countLimit }"
        >
         <span class="text-[--primary-invert-alfa-60] text-[12px] md:text-[16px] font-[700] leading-[90%] tracking-[0.4px] md:tracking-[0.8px] uppercase flex-1">
           {{ userAd?.countLimit }}
         </span>
        </div>
        <span class="text-[--primary-invert-alfa-60] text-[11px] md:text-[12px] font-normal leading-[150%] tracking-[0.6px] truncate ">
          {{ $tc('postAnnouncement.freeAds', userAd?.countLimit === 5 ? 0 : userAd?.countLimit)  }}
        </span>
      </div>
      <Button
        v-if="userAd?.countLimit"
        size="none"
        custom-class="p-[14px_20px] md:p-[16px_18px]"
        :text="$t('button.publish')"
        @handle-click="addAds"
      />
      <Button
        v-else
        size="none"
        custom-class="p-[14px_20px] md:p-[16px_18px]"
        :text="payButton"
        @handle-click="payAds"
      />

    </div>
  </Dialog>
</template>
<script setup>
import {computed, onMounted, onUnmounted, ref, } from 'vue';
import { useStore } from 'vuex';
import { i18n } from '@/i18n';
import Button from '@/common/Button.vue';
import Dialog from '@/common/Dialog.vue';
import Textarea from '@/common/Textarea.vue';
import { handleAddAds, handleGetUserActiveAds, handleGetUserAdsPrice} from '@/api/Profile';
import { updateDragStatus } from '@/helpers/imageUpload';
import { getGoingTimeFromDay } from '@/helpers/date';
import { handleModerateImage } from '@/api/Images';
import CrossIcon from '@/assets/icons/cross.vue';
import AddToFavoritesIcon from '@/assets/icons/addToFavoritesIcon.vue';
import InfoCircleIcon from '@/assets/icons/infoCircleIcon.vue';
import SkyIcon from '@/assets/icons/skyIcon.vue';
import TimerIcon from '@/assets/icons/timerIcon.vue';

const store = useStore();

const userAd = computed(() => store.getters['profile/userAd']);

const fileInput = ref(null);
const image = ref(null);
const isDragging = ref(false);
const isModerated = ref(false);
const price =ref({});

const message = computed(()=> {
  if (userAd?.value.isPublished) return i18n.global.t('postAnnouncement.coverYourAd');

  if (isModerated.value) return i18n.global.t('postAnnouncement.photoDoesntModerated');

  return i18n.global.t('postAnnouncement.photo.description');
});

const payButton = computed(() => {
  return i18n.global.t('button.pay99', { price: price.value?.price || 99 });
});

onUnmounted(() => {
  getUserAds();
});

onMounted(() => {
  const storedAdData = JSON.parse(localStorage.getItem('adData'));

  if (storedAdData) {
    getUserAds();
  }

  if (userAd.value?.countLimit === 0) {
    adsPrice();
  }
});

async function adsPrice() {
  const response = await handleGetUserAdsPrice();
  if (response?.data?.price && response?.data?.old_price){
    price.value = {
      price: response.data.price,
      old_price: response.data.old_price,
    };
  }


}

function updateLocalStorageData(newData) {
  const storedAdData = JSON.parse(localStorage.getItem('adData')) || {};
  const updatedData = { ...storedAdData, ...newData };
  localStorage.setItem('adData', JSON.stringify(updatedData));
}

function closeAdDialog() {
  localStorage.removeItem('adPlace');
  localStorage.removeItem('adData');
  
  store.dispatch('main/closeDialog', 'adPlace');
}

async function getUserAds() {
  const response = await handleGetUserActiveAds();
  const storedAdData = JSON.parse(localStorage.getItem('adData'));
  const adPlace =localStorage.getItem('adPlace');

  if (response && response.status === 200) {
    if (storedAdData && adPlace) {
      store.dispatch('profile/setUserAd', {
        ...response?.data?.ad,
        countLimit: response?.data?.remaining_ads_count || 0,
        isPublished: !!response?.data?.ad?.content,
        content: storedAdData.content || '',
        photo_url: storedAdData.photo_url || '',
      });
      image.value = {url: userAd.value?.photo_url};
    } else {
      store.dispatch('profile/setUserAd', {
        ...response?.data?.ad,
        countLimit: response?.data?.remaining_ads_count || 0,
        isPublished: !!response?.data?.ad?.content,
      });
    }
  }
}

async function addAds () {
  if (!userAd?.value.content) return;
  localStorage.removeItem('adPlace');
  localStorage.removeItem('adData');

  const formData = new FormData();

  formData.append('content', userAd?.value.content);

  if (image.value?.file) {
    formData.append('photo', image.value.file);
  }

  const response = await handleAddAds(formData);

  if (response && (response.status === 200 || response.status === 201)) {
    closeAdDialog();
    store.dispatch('main/openDialog', 'postAdNote');
  }
}

async function payAds () {
  localStorage.setItem('adPlace',true);
  updateLocalStorageData({ content: userAd.value?.content });
  const responses = [
    {
      id: 'payAds',
      title: i18n.global.t('payment.payAds.details.title'),
      discountedPrice: price.value?.price,
      oldPrice: price.value?.old_price,
    },
    {
      id: 'way',
      title: i18n.global.t('payment.payAds.details.description'),
      discountedPrice: i18n.global.t('payment.paymentSubscription.byRussianBankCard.title'),
    },
  ];

  store.dispatch('payment/setPayAds', responses);

  store.dispatch('main/closeDialog', 'adPlace');
}

function triggerFileInput() {
  if (userAd?.value.isPublished) return;

  if (!image.value) {
    fileInput.value.click();
  }
}

function handleFileChange(event) {
  const file = event.target.files[0];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

  if (validImageTypes.includes(file?.type)) {
    processFiles(file);
  }

  event.target.value = '';
}

function dragStatus(event) {
  if (userAd?.value.isPublished) return;

  isDragging.value = updateDragStatus(event);
}

function onDrop(event) {
  if (userAd?.value.isPublished) return;

  event.preventDefault();
  isDragging.value = false;

  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
  const file = event.dataTransfer.files[0];

  if (validImageTypes.includes(file?.type)) {
    processFiles(file);
  }
}
function removeImage(){
  image.value = null;
  store.dispatch('profile/setUserAd', {
    ...userAd.value,
    photo_url: ''
  });
}
function handleTextChange(value){
  store.dispatch('profile/setUserAd', {
    ...userAd.value,
    content: value
  });
}

function processFiles(file) {
  const selectedImage = [];
  const reader = new FileReader();

  reader.onload = async(e) => {
    const url = e.target.result;

    selectedImage.push({ url, file });

    const response = await handleModerateImage(file);

    if(response?.data?.status === 'approved') {
      image.value = { url, file };
      isModerated.value = false;
      updateLocalStorageData({ photo_url: response?.data?.url });


      store.dispatch('profile/setUserAd', {
        ...userAd.value,
        photo_url: URL.createObjectURL(image.value.file)
      });
    } else {
      isModerated.value = true;
    }
  };

  reader.readAsDataURL(file);
}
</script>
<style>
.add-to-favorites-icon-dragging path {
  fill: var(--primary-pink);
}

.add-to-favorites-icon path {
  fill: var(--primary-invert-alfa-40);
}
</style>
