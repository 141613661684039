import { apiClient } from '@/main';

const authenticationApi = process.env.VUE_APP_BASE_URL;

export async function handleGoogleAuthentication() {
  try {
    window.location.href = `${authenticationApi}/auth/google`;
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetAccessToken(id) {
  try {
    return await apiClient.get(`/user/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendUserInformation(payload) {
  try {
    return await apiClient.post('/user/profile', payload);
  } catch (error) {
    console.error(error);

    return error;
  }
}

export async function handleGetFetishes() {
  try {
    return await apiClient.get('/fetishes');
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetGoals() {
  try {
    return await apiClient.get('/goals');
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendUserGoalsAndFetishes(payload) {
  try {
    return await apiClient.post('/user/preferences', payload);
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function handleCheckOnlineStatus(payload) {
  try {
    return await apiClient.put('/user/online-status', payload);
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function handleHideProfile(payload) {
  try {
    return await apiClient.post('/site/toggle', payload);
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function getHideProfile() {
  try {
    return await apiClient.get('/site/toggle');
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function logoutProfile() {
  try {
    return await apiClient.post('/logout',);
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteProfile() {
  try {
    return await apiClient.delete('/site/delete',);
  } catch (error) {
    console.error(error);
    return error;
  }
}
