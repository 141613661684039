import { profileListDefaultValues, userDefaultValues } from '@/constants/profileSettings';
import _ from 'lodash';

const state = () => ({
  userInfo: userDefaultValues,
  editingUserInfo: userDefaultValues,
  selectedUserPage: userDefaultValues,
  privacySettings: {},
  blacklistUsers: [],
  userImagesList: [],
  filterList: _.cloneDeep(profileListDefaultValues),
  selectedImage: '',
  userLikesList: [],
  userLikesPagination: {},
  systemWarningKey:'',
  userFeedPagination: {},
  userUnreadLikesCount: 0,
  userFeedList: [],
  userAd: {},
});

const getters = {
  userInfo: (state) => state.userInfo,
  editingUserInfo: (state) => state.editingUserInfo,
  selectedUserPage: (state) => state.selectedUserPage,
  privacySettings: (state) => state.privacySettings,
  blacklistUsers: (state) => state.blacklistUsers,
  userImagesList: (state) => state.userImagesList,
  filterList: (state) => state.filterList,
  selectedImage: (state) => state.selectedImage,
  systemWarningKey: (state) => state.systemWarningKey,
  userFeedList: (state) => state.userFeedList,
  userLikesList:(state) => state.userLikesList,
  userLikesPagination:(state) => state.userLikesPagination,
  userFeedPagination:(state) => state.userFeedPagination,
  userUnreadLikesCount:(state) => state.userUnreadLikesCount,
  userAd:(state) => state.userAd,
};

const actions = {
  setUserInfo({ commit }, payload) {
    commit('SET_USER_INFO', payload);
  },

  setEditingUserInfo({ commit }, payload) {
    commit('SET_EDITING_USER_INFO', payload);
  },

  setSelectedUserPage({ commit }, payload) {
    commit('SET_SELECTED_USER_PAGE', payload);
  },

  setEditingUserInfoAllList({ commit }, payload) {
    commit('SET_EDITING_USER_INFO_All_LIST', payload);
  },

  setPrivacySettingsList({ commit }, payload) {
    commit('SET_PRIVACY_SETTINGS_LIST', payload);
  },

  setBlacklistUsers({ commit }, payload) {
    commit('SET_BLACK_LIST_USERS', payload);
  },

  setUserImagesList({ commit }, payload) {
    commit('SET_USER_IMAGES_LIST', payload);
  },

  setFilterList({ commit }, payload) {
    commit('SET_FILTER_LIST', payload);
  },

  setSelectedImage({ commit }, image) {
    commit('SET_SELECTED_IMAGE', image);
  },

  setSystemWarningKey({ commit }, key) {
    commit('SET_SYSTEM_WARNING_KEY', key);
  },

  setUserFeedList({ commit }, payload) {
    commit('SET_USER_FEED_LIST', payload);
  },

  setClearFilterList({ commit }){
    commit('SET_CLEAR_FILTER_LIST', _.cloneDeep(profileListDefaultValues));
  },

  setUserLikesList({ commit }, payload) {
    commit('SET_USER_LIKES_LIST', payload);
  },

  setUserLikesPagination({ commit }, payload) {
    commit('SET_USER_LIKES_PAGINATION', payload);
  },

  setUserFeedPagination({ commit }, payload) {
    commit('SET_USER_FEED_PAGINATION', payload);
  },

  setUserUnreadLikesCount({ commit }, payload) {
    commit('SET_USER_UNREAD_LIKES_COUNT', payload);
  },

  setUserAd({ commit }, payload) {
    commit('SET_USER_AD', payload);
  },
};

const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  SET_EDITING_USER_INFO(state, payload) {
    state.editingUserInfo[payload.key] = payload.value;
  },

  SET_FILTER_LIST(state, payload) {
    state.filterList[payload.key] = payload.value;
  },

  SET_EDITING_USER_INFO_All_LIST(state, payload) {
    state.editingUserInfo = payload;
  },

  SET_SELECTED_USER_PAGE(state, payload) {
    state.selectedUserPage = payload;
  },

  SET_PRIVACY_SETTINGS_LIST(state, payload) {
    state.privacySettings = payload;
  },

  SET_BLACK_LIST_USERS(state, payload) {
    state.blacklistUsers = payload;
  },

  SET_USER_IMAGES_LIST(state, payload) {
    state.userImagesList = payload;
  },

  SET_SELECTED_IMAGE(state, image) {
    state.selectedImage = image;
  },

  SET_SYSTEM_WARNING_KEY(state, key) {
    state.systemWarningKey = key;
  },

  SET_USER_FEED_LIST(state, payload) {
    state.userFeedList = payload;
  },

  SET_USER_LIKES_LIST(state, payload) {
    state.userLikesList = payload;
  },

  SET_CLEAR_FILTER_LIST(state,payload ) {
    state.filterList = payload;
  },

  SET_USER_LIKES_PAGINATION(state,payload ) {
    state.userLikesPagination = payload;
  },

  SET_USER_FEED_PAGINATION(state,payload ) {
    state.userFeedPagination = payload;
  },

  SET_USER_UNREAD_LIKES_COUNT(state,payload) {
    state.userUnreadLikesCount = payload;
  },

  SET_USER_AD(state,payload) {
    state.userAd = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
